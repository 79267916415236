/* App.css*/
.App {
	text-align: center;
	/* color: Green; */
}
.container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.item {
	min-width: 33rem;
	text-align: left;
}

.dtoken {
	color: green;
}
